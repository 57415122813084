<style lang="less">
  .org-tree {
    .fm-tree-node .fm-tree-node, .fm-tree-node .fm-tree-add {
      padding: 2px 0 2px 18px !important;
    }
  }
</style>

<style scoped lang="less">
  .person-page {
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
  }
  .person-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    // padding: 10px 20px;
    // align-items: center;
    background-color: #FFF;
    // display: flex;
    // justify-content: space-between;
  }
  .person-lists {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #FFF;
    height: 0;
    flex: 1;
    position: relative;
  }
  .org-info {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #FFF;
    width: 100%;
    height: calc(100% - 48px);
    display: flex;
  }
  .org-tree {
    width: 300px;
    height: calc(100% - 10px);
    margin-bottom: 10px;
    padding-left: 10px;
    overflow-y: auto;
    border-right: 1px solid rgba(0,0,0,0.1);
  }
  .org-count {
    flex: 1;
    height: calc(100% - 10px);
  }
.img-c {
  height: 144px;
  width: 105px;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 1px solid #eee;
  .no-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #999;
  }
  img {
    max-height: 144px;
    max-width: 105px;
  }
}
.file-d-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-d-footer-wx {
  display: none;
  justify-content: center;
  align-items: center;
}
.edu-his {
  width: 80%;
  border-spacing: 1px;
  border-collapse: collapse;
  border: 1px solid #E8E8E8;
  background-color: #FFF;
  tr:first-of-type, td:first-of-type {
    background: #FAFAFA;
  }
  td {
    color: rgba(0, 0, 0, 0.45);
    border: 1px solid #E8E8E8;
    padding: 5px 8px;
  }
}
.is-history {
  display: flex;
  align-items: center;
  color: #F4628F;
  &::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #F4628F;
    margin-right: 5px;
  }
}
</style>

<template>
  <div class="person-page" v-loadingx="loading.lists">
    <div class="person-header">
      <fm-title :title-text="title || '党员管理'" :note-text="noteText" @clickTitleMenu="clickTitleMenu" :title-menus="titleMenus">
        <div v-if="isHistory" class="is-history">
          当前数据为历史数据
          <fm-date-picker @change="loadData" style="margin-left: 30px;width: 170px;" v-model="historyDate" type="date"></fm-date-picker>
        </div>
      </fm-title>
    </div>
    <div class="org-info" v-if="!showList">
      <div class="org-tree">
        <OrgTree @clickOrg="clickOrg"></OrgTree>
      </div>
      <div class="org-count">
        <fm-table-new
          ref="table"
          :simple-filter="true"
          :full="true"
          auto-height
          border="row"
          @row-click="rowClick"
          size="large"
          :stripe="false"
          emptyPlaceholder="-"
          :columns="columnsOrgCount"
          :data-list="orgCountData"
        ></fm-table-new>
      </div>
    </div>
    <div class="person-lists" v-else>
      <fm-table-new
        ref="table"
        :full="true"
        auto-height
        :empty-search="emptySearch"
        :toolbox="showToolBox"
        :export-file-name="exportFileName"
        border="row"
        size="large"
        :stripe="false"
        emptyPlaceholder="-"
        :columns="columns"
        :data-list="dataList"
        @counterChange="countDataChange"
        :handler-counter="countFun"
      ></fm-table-new>
    </div>
    <fm-modal :mask-closable="true" v-model="sortModal" v-if="sortModal" width="800px" title="默认排序设置" theme="mh-blackt">
      <div style="width: 100%;height: 60vh;">
      <fm-table-new
        ref="table"
        :simple-filter="true"
        :full="true"
        auto-height
        border="row"
        :defaultOptionsFilterMethod="defaultOptionsFilterMethod"
        size="small"
        :stripe="false"
        emptyPlaceholder="-"
        :columns="columnsSort"
        :data-list="dataList"
      ></fm-table-new>
      </div>
    </fm-modal>
    <fm-modal :mask-closable="false" v-model="modal" v-if="modal" width="1100px" title="员工信息" theme="mh-blackt">
      <worker-form
        :source-data="choose"
        :fun-auth="{
          edit: funAuth.edit,
          add: funAuth.add
        }"
        :read-only="isRead"
        ref="worker_form"></worker-form>
      <div class="modal-footer-btns" v-if="!isRead">
        <!-- <fm-btn @click="saveModal('sq')" :v-loadingx="loading.modal" v-if="funAuth.applyWorkerAdd || funAuth.applyWorkerEdit">申请{{choose && choose.id ? '修改' : '添加'}}</fm-btn> -->
        <fm-btn @click="saveModal" :v-loadingx="loading.modal" v-if="funAuth.edit || funAuth.add">确定</fm-btn>
        <fm-btn @click="closeModal">取消</fm-btn>
      </div>
    </fm-modal>
    <resume v-if="choose && funAuth.resume" :positionMap="{}" :edu-his-data="eduHisdData" v-model="resume" :data="choose" @cancel="resume = false" :photo-src="src"></resume>
    <fm-modal title="员工信息明细" :mask-closable="true" v-model="showDetail" width="80%" v-if="showDetail">
      <allInfo :worker-id="choose.id" :history-date="isHistory ? historyDate : null"></allInfo>
    </fm-modal>
    <A4Print ref="print" />
  </div>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
import { workerRequest } from '../../api'

import {
  fileRequest
} from '@/api'

import Resume from './resume'
import allInfo from '../person/info/allInfo'

import OrgTree from '../../components/orgTree'

import SortNum from './worker/sortNum'

import WorkerForm from './worker/workerForm'

import A4Print from '@/components/a4print/index'

import {
  getAge
} from '@/syslib/tools'

export default {
  props: {
    funAuthOther: Object,
    title: {
      type: String
    },
    isDiscipline: {
      type: String
    }
  },
  components: { Resume, allInfo, WorkerForm, A4Print, OrgTree},
  data () {
    return {
      orgCountMap: {},
      showList: false,
      chooseOrg: null,
      chooseWorkerOrg: null,
      orgTreeData: [],
      sortModal: false,
      emptySearch: '__null',
      eduHisdData: [],
      isRead: false,
      isHistory: false,
      historyDate: new Date(),
      lists: [],
      modal: false,
      resume: false,
      choose: null,
      sourceData: null,
      loading: {
        modal: false,
        lists: false
      },
      noteText: '',
      loadingFile: false,
      modalPhoto: false,
      files: [],
      src: null,
      showDetail: false
    }
  },
  computed: {
    needOrgName2 () {
      return this.$store.getters.sysConfig['needOrgName2']
    },
    columnsOrgCount () {
      let vm = this
      return [{
        title: '党组织名称',
        field: 'name'
      },
      {
        title: '入库党员',
        field: 'rk',
        render: (h, rowData) => {
          return h('div', vm.orgCountMap[rowData.id] ? vm.orgCountMap[rowData.id].rk : 0)
        }
      },
      {
        title: '预备党员',
        field: 'yb',
        render: (h, rowData) => {
          return h('div', vm.orgCountMap[rowData.id] ? vm.orgCountMap[rowData.id].yb : 0)
        }
      },
      {
        title: '女党员',
        field: 'ndy',
        render: (h, rowData) => {
          return h('div', vm.orgCountMap[rowData.id] ? vm.orgCountMap[rowData.id].ndy : 0)
        }
      },
      {
        title: '少数民族党员',
        field: 'ssmz',
        render: (h, rowData) => {
          return h('div', vm.orgCountMap[rowData.id] ? vm.orgCountMap[rowData.id].ssmz : 0)
        }
      },
      {
        title: '大专以上学历党员',
        field: 'dzys',
        render: (h, rowData) => {
          return h('div', vm.orgCountMap[rowData.id] ? vm.orgCountMap[rowData.id].dzys : 0)
        }
      }]
    },
    workerTableType1 () {
      return this.$store.getters.sysConfig['workerTableType1']
    },
    showToolBox () {
      return ['export'].concat(['table-config'].filter(v => this.funAuth[v]).map(v => v.replace('table-', '')))
    },
    titleMenus () {
      let data = [{
      //   key: 'sort',
      //   label: '默认排序设置'
      // },
      // {
      //   key: 'history',
      //   label: '历史数据'
      // },
      // {
        key: 'add',
        label: '新增信息'
      },
      {
        key: 'output',
        label: '导出'
      }].filter((v) => this.funAuth[v.key])
      let dataAll = this.filters ? [{
        key: 'show_all',
        label: '查看全部人员信息'
      }] : []
      if (this.showList) {
        dataAll.push({
          key: 'back',
          label: '返回'
        })
      }
      return this.isHistory ? [
        { key: 'current', label: '当前数据' },
        { key: 'output', label: '导出' }
      ] : dataAll.concat(data)
    },
    funAuth () {
      return this.funAuthOther || this.$authFunsProxy
    },
    baseColumns () {
      let data = this.baseColumns1.filter(v => this.needOrgName2 || v.field !== 'zg')
      data = data.filter(v => this.workerTableType1 || v.workerTableType1 !== true)
      data.forEach(v => {
        if (v.filters) {
          v.filters.push({
            value: '__null',
            label: '值为空'
          })
        }
      })
      return data
    },
    columnsSort () {
      return [
        { title: '姓名', field: 'name', sort: true},
        { title: '身份证号码', field: 'idNo', sort: true},
        {
          title: '默认排序',
          field: 'position',
          sort: true,
          dataType: Number,
          filterRange: true,
          render: (h, rowData) => {
            return h(SortNum, {
              props: {
                workerData: rowData
              },
              on: {
                sortNumChange: (sortNum) => {
                  rowData.position = sortNum
                  let list1 = this.lists.filter(v => v.position !== null)
                  let List2 = this.lists.filter(v => v.position === null)
                  list1.sort((a, b) => Number(a.position) - Number(b.position))
                  this.lists = list1.concat(List2)
                }
              }
            })
          }
        }
      ]
    },
    baseColumns1 () {
      let isHistory = this.isHistory
      return [
        // { title: '工号', field: 'code', fixed: 'left', sort: true, width: 120 },
        // { title: '机构名称', field: 'orgName', fixed: 'left', sort: true, width: 200 },
        // { title: '机构名称2', field: 'zg', fixed: 'left', sort: true, width: 130 },
        { title: '姓名', field: 'name', fixed: 'left', sort: true, width: 120},
        { title: '性别', field: 'sex', sort: true, width: 120, filters: [{
          label: '男',
          value: '男'
        },
        {
          label: '女',
          value: '女'
        }]
        },
        { title: '民族', width: 120, field: 'clan', sort: true, filters: this.$store.getters.selectDatas['worker_clan_list'].map(v => {
          return {
            label: v.label,
            value: v.key
          }
        })},
        { title: '身份证号码', field: 'idNo', sort: true, width: 184 },
        {
          title: '出生年月', field: 'birth', width: 250, sort: true, dataType: Date, filterRange: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.birth ? rowData.birth.slice(0, 10) : '-')
          }
        },
        {
          title: '年龄', field: 'age', sort: true, dataType: Number, width: 120, filterRange: true
        },
        {
          title: '入党日期', field: 'rdsj', width: 250, sort: true, dataType: Date, filterRange: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.rdsj ? rowData.rdsj.slice(0, 10) : '-')
          }
        },
        {
          title: '转正日期', field: 'zzrq', width: 250, sort: true, dataType: Date, filterRange: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.zzrq ? rowData.zzrq.slice(0, 10) : '-')
          }
        },
        {
          title: '党龄', field: 'dage', sort: true, dataType: Number, width: 120, filterRange: true
        },
        { title: '学历', field: 'education', width: 120, sort: true, filters: this.$store.getters.selectDatas['worker_education_list'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        { title: '学位', field: 'degree', width: 120, sort: true, filters: this.$store.getters.selectDatas['worker_edu_degree'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        // { title: '籍贯', field: 'hometown', width: 120, sort: true },
        { title: '联系电话', field: 'phone', width: 120, sort: true },
        // ...this.$store.getters.orgTypeList.map((v) => {
        //   return {
        //     title: '所属' + v.data.name + '机构',
        //     field: 'orgType_' + v.data.id,
        //     width: 120,
        //     sort: true,
        //     search: true
        //   }
        // }),
        // { title: '默认排序',width: 120, field: 'position', sort: true },
        {
          title: '操作', field: 'action', fixed: 'right', export: false, width: 200,
          render: (h, row) => {
            let data = [
              h('fm-btn', {
                props: { text: true },
                style: { padding: 0, marginLeft: '4px' },
                on: {
                  click: () => {
                    this.isRead = false
                    this.setChoose(row)
                  }
                }
              }, '修改'),
              h('fm-btn', {
                props: { text: true },
                style: { padding: 0, marginLeft: '4px' },
                on: {
                  click: async () => {
                    let res = await this.$dialog.confirm('确定删除么?')
                    if (res) {
                      await workerRequest.del(row.id)
                      this.$notice.info({
                        title: '系统提示',
                        desc: '员工信息已删除'
                      })
                      this.loadData()
                      this.$store.dispatch('loadWorkerList', true)
                    }
                  }
                }
              }, '删除'),
            ]
            let newData = []
            if (!isHistory && this.funAuth.edit) {
              newData.push(data[0])
            }
            if (!isHistory && this.funAuth.del) {
              newData.push(data[1])
            }
            return h('div', newData)
          }
        }
      ].map(v => {
        v.search = true
        return v
      })
    },
    columns () {
      return this.$getTableConfig('main', this.baseColumns)
    },
    // orgTypeIds () {
    //   return this.$store.getters.orgTypeList.map(v => v.data.id)
    // },
    orgCountData () {
      if (!this.chooseOrg) {
        return []
      }
      let data = []
      this.getOrgChild(data, this.chooseOrg)
      return data
    },
    dataList () {
      let data = this.lists.filter(v => !this.filters || this.filters(v))
      if (this.chooseWorkerOrg) {
        if (this.orgCountMap[this.chooseWorkerOrg.id]) {
          data = data.filter(v => this.orgCountMap[this.chooseWorkerOrg.id].workerIds.includes(v.id))
        } else {
          data = []
        }
      }
      return data
    },
    filters () {
      if (this.$route.query && Object.keys(this.$route.query).length) {
        if (this.$route.query.orgTreeCode) {
          return (item) => {
            return item.userOrgRoleList.filter(v => v.orgTreeCode && v.orgTreeCode.startsWith(this.$route.query.orgTreeCode)).length > 0
          }
        } else if (this.$route.query.orgId && this.$route.query.roleId) {
          return (item) => {
            return item.userOrgRoleList.filter(v => v.orgId === this.$route.query.orgId && v.roleId === this.$route.query.roleId).length > 0
          }
        }
      } else {
        return null
      }
      return null
    }
  },
  methods: {
    rowClick (data) {
      this.chooseWorkerOrg = data.row
      this.showList = true
    },
    getOrgCountMap (datas) {
      let map = {}
      datas.forEach(v => {
        let userOrgRoleList = v.userOrgRoleList || []
        userOrgRoleList.forEach(orgRole => {
          if (orgRole.orgId) {
            let key = orgRole.orgId
            if (!map[key]) {
              map[key] = {
                workerIds: [],
                rk: 0,
                yb: 0,
                ndy: 0,
                ssmz: 0,
                dzys: 0
              }
            }
            if (!map[key].workerIds.includes(v.id)) {
              map[key].workerIds.push(v.id)
              map[key].rk += 1
              if (v.sex === '女') {
                map[key].ndy += 1
              }
              if (v.clan  && v.clan !== '汉族') {
                map[key].ssmz += 1
              }
              if (v.education  && ['博士研究生', '硕士研究生', '本科', '大专'].includes(v.education)) {
                map[key].dzys += 1
              }
            }
          }
        })
      })
      this.orgCountMap = map
    },
    clickOrg (data) {
      this.chooseOrg = data
    },
    getOrgChild (datas, org) {
      datas.push(org)
      if (org.child) {
        org.child.forEach(v => {
          this.getOrgChild(datas, v)
        })
      }
    },
    defaultOptionsFilterMethod (filterValue, columnValue) {
      return filterValue === columnValue
    },
    async loadFile () {
      this.src = null
      let path = '/worker/' + this.choose.id + '/'
      this.loadingFile = true
      try {
        let data = await fileRequest.get({
          path: path
        })
        this.files = data
        if (this.files.length > 0) {
          const fileData = await fileRequest.download({
            path: this.files[0].path
          })
          const src = await new Promise(resolve => {
            let reader = new FileReader()
            reader.readAsDataURL(fileData)
            reader.onload = () => resolve(reader.result)
          })
          this.src = src
        } else {
          this.src = null
        }
      } catch (error) {
        console.log(error)
        this.files = []
        this.src = null
      }
      this.loadingFile = false
    },
    exportFileName () {
      return '员工信息' + this.$datetime.format(new Date(), 'YMD')
    },
    countFun (data) {
      return data.length
    },
    countDataChange (data) {
      this.noteText = '总数:' + data
    },
    clickTitleMenu (key) {
      if (key === 'show_all') {
        this.$router.replace({name: this.$route.name})
      } else if (key === 'add') {
        this.choose = {
          orgName: this.$store.getters.sysConfig['defaultOrgName'] || '',
          zg: this.$store.getters.sysConfig['defaultOrgName2'] || '',
        }
        this.sourceData = {}
        this.isRead = false
        this.modal = true
      } else if (key === 'output') {
        this.exportData()
      } else if (key === 'current') {
        this.isHistory = false
        this.loadData()
      } else if (key === 'history') {
        this.isHistory = true
        this.loadData()
      } else if (key === 'sort') {
        this.sortModal = true
      } else if (key === 'back') {
        this.showList = false
      }
    },
    exportData () {
      this.$refs.table.openExport()
    },
    async saveModal () {
      let vm = this.$refs['worker_form']
      if (vm && typeof vm.getFormData === 'function') {
        let data = vm.getFormData()
        if (data) {
          this.choose = data
        } else {
          return
        }
      } else {
        return
      }
      this.loading.modal = true
      if (this.choose.id) {
        await workerRequest.update(this.choose.id, this.choose)
        this.$notice.success({
          title: '系统提示',
          desc: '员工信息已修改'
        })
      } else {
        await workerRequest.add(this.choose)
        this.$notice.success({
          title: '系统提示',
          desc: '员工信息已新增'
        })
      }
      this.$store.dispatch('loadWorkerList', true)
      this.loadData()
      this.loading.modal = true
      this.choose = null
      this.sourceData = null
      this.modal = false
    },
    closeModal () {
      this.modal = false
      this.choose = null
      this.sourceData = null
    },
    setChoose (row) {
      this.sourceData = row
      this.choose = Object.assign({}, row)
      this[!this.isRead ? 'modal' : 'resume'] = true
    },
    async loadData () {
      this.loading.lists = true

      let datas = []
      if (this.funAuth.get) {
        datas = await workerRequest.getDetail()
      } else if (this.funAuth.getMy) {
        datas = await workerRequest.getDetailMy()
      } else if (this.funAuth.getMyOrg) {
        datas = await workerRequest.getDetailMyOrg()
      } else if (this.funAuth.getMyOrgContainChild) {
        datas = await workerRequest.getDetailMyOrgContainChild()
      }
      this.lists = datas.map(v => {
        return Object.assign({}, v.worker, {
          workerEduDatas: v.workerEduDatas.filter(v1 => !this.isHistory || (v1.endDate && new Date(v.endDate) <= this.historyDate)),
          workerJobTitleDatas: this.isHistory ? v.workerJobTitleDatas.filter(v1 => new Date(v1.startDate) <= this.historyDate) : v.workerJobTitleDatas
        })
      })

      let now = this.isHistory ? this.historyDate : new Date()

      this.lists.forEach((v) => {
        // v.positionIds = v.positionIds ? v.positionIds.split(',') : []
        // v.positionIds = v.positionIds.map(v1 => Number(v1))
        v.age = getAge(v.birth, now)
        let info = v.eduHis ? JSON.parse(v.eduHis) : {}
        v.rdsj = info.rdsj
        v.zzrq = info.zzrq
        v.dage = getAge(info.zzrq, now)
        let eduHisd = v.workerEduDatas
        v.eduHisd = eduHisd
        v.fEduHisd = eduHisd.find(v => v.type === '第一学历')
        v.lEduHisd = eduHisd.find(v => v.type === '最高学历')

        v.workerJobTitles = v.workerJobTitleDatas
        v.fWjt = v.workerJobTitles.length > 0 ? v.workerJobTitles[0] : null

        let cWorkerJobTitles = v.workerJobTitles.filter(v2 => !v2.endDate)
        v.lWjt = cWorkerJobTitles.length > 0 ? cWorkerJobTitles[cWorkerJobTitles.length - 1] : null

        let newData = []
        v.userOrgRoleList.forEach(v => {
          if (v.roleName && !newData.includes(v.roleName)) {
            newData.push(v.roleName)
          }
        })
        v.positionIdText = newData.join(',')
        // v.positionIdsText = v.positionIds && v.positionIds.length > 0 ? v.positionIds.map(v => this.positionMap[v]).filter(v => v).join(',') : null
        v.srgwlb = v.fWjt && v.fWjt.jobTitleType ? v.fWjt.jobTitleType : null
        v.srgwjb = v.fWjt && v.fWjt.type ? (v.fWjt.type === '医师' ? v.fWjt.yTitle : v.fWjt.hTitle) : null
        v.srgwsj = v.fWjt && v.fWjt.startDate ? v.fWjt.startDate.slice(0, 10) : null
        
        v.xrgwlb = v.lWjt && v.lWjt.jobTitleType ? v.lWjt.jobTitleType : null
        v.xrgwjb = v.lWjt && v.lWjt.type ? (v.lWjt.type === '医师' ? v.lWjt.yTitle : v.lWjt.hTitle) : null
        v.xrgwTopLevel = v.lWjt && v.lWjt.topLevel ? v.lWjt.topLevel : null
        v.xrgwMLevel = v.lWjt && v.lWjt.mLevel ? v.lWjt.mLevel : null
        v.xrgwJobTitleTitle = v.lWjt && v.lWjt.jobTitleTitle ? v.lWjt.jobTitleTitle : null
        v.xrgwsj = v.lWjt && v.lWjt.startDate ? v.lWjt.startDate.slice(0, 10) : null
      })
      this.lists = this.lists.filter((v) => {
        let pass = true
        if (this.isHistory && v.age && v.age < 0) {
          pass = false
        }
        return pass
      })
      this.loading.lists = false
      this.getOrgCountMap(this.lists)
    }
  },
  async mounted () {
    if (this.funAuth.get || this.funAuth.getMy || this.funAuth.getMyOrg || this.funAuth.getMyOrgWitchChild) {
      this.loadData()
    }
    this.$store.dispatch('loadOrgTree')
  }
}
</script>
