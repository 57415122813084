<template>
  <fm-tree :nodes="nodes" @selected="chooseOrg" search is-selected></fm-tree>
</template>

<script>
export default {
  computed: {
    nodes () {
      let datas = this.$store.getters.orgTree
      let fn = (org) => {
        return {
          title: org.name,
          data: org
        }
      }
      let result = datas.map(fn)
      let each = (nodes) => {
        nodes.forEach(node => {
          node.children = node.data.child.map(fn)
          each(node.children)
        })
      }
      each(result)
      return result
    }
  },
  methods: {
    chooseOrg (data) {
      this.$emit('clickOrg', data.data.data)
    }
  }
}
</script>

<style lang="less" scoped>
</style>